<template>
  <footer>
    <div class="foot-con hidden-md-and-down">
      <div style="display: flex;justify-content: space-between">
        <div>
          <h4 class="ft-h4">关于平台</h4>

          <div class="list-con">
            <a class="ft-text" href="http://adminsfxysys.lyrjfwpt.cn">平台管理</a>
            <div class="wx">
              <div class="ziti">
                <div class="arrow"></div>
                <p>官方微信</p>
                <img src="../assets/img/weixin.jpg" alt="" width="100" height="100">
              </div>
            </div>
            <div class="wb">
              <div class="wbt">
                <div class="arrow"></div>
                <p>官方微博</p>
                <img src="../assets/img/weibo.jpg" alt="" width="100" height="100">
              </div>
            </div>
            <div class="ft-text">
              <el-popover
                  popper-class="xcx-pop"
                  placement="top-start"
                  trigger="hover"
                  width="20px"
                  content=""
              >
                <p style="textAlign: center;color: #2d2d2d">微信扫一扫</p>
                <img class="QR-img" src="../assets/img/QR.jpg" alt="" />
                <!-- <el-button slot="reference">
                  <img
                      class="xcx-icon"
                      src="../assets/image/icon/icon_footer.png"
                      alt=""
                  />
                </el-button> -->
              </el-popover>
            </div>
          </div>
        </div>
        <div>
          <h4 class="ft-h4">联系我们</h4>
          <div class="list-con">
            <p class="ft-text">联系电话：0379-68618506</p>
            <p class="ft-text">电子邮箱：xcb@lynu.edu.cn</p>
            <p class="ft-text">地址：中国洛阳市伊滨区吉庆路6号</p>
          </div>
        </div>
        <div>
          <h4 class="ft-h4">帮助</h4>
          <div class="list-con">
            <p class="ft-text">建议采用ie8以上</p>
            <p class="ft-text">chrome、firefox、Safari5+浏览器</p>
            <p class="ft-text">360、腾讯浏览器采用极速内核</p>
          </div>
        </div>
       <!-- <div>
          <h4 class="ft-h4">版权所有</h4>
          <div class="list-con">
            <p class="ft-text">洛阳师范学院</p>
            <p class="ft-text">豫ICP备10005974号</p>
            <p class="ft-text">豫公网安备 41031102000172号</p>
          </div>
        </div>
         <div>
          <h4 class="ft-h4">媒体</h4>
          <div class="list-con">
            <div class="wx">
              <div class="ziti">
                <div class="arrow"></div>
                <p>官方微信</p>
                <img src="../assets/img/weixin.jpg" alt="" width="100" height="100">
              </div>
            </div>
            <div class="wb">
              <div class="wbt">
                <div class="arrow"></div>
                <p>官方微博</p>
                <img src="../assets/img/weibo.jpg" alt="" width="100" height="100">
              </div>
            </div>
          </div>
        </div> -->
        </div>
      </div>
      <div class="ship">
        <ul>
          <li><a href="#" class="friendship">友情链接:</a></li>
          <li><a href="https://bdat.nuist.edu.cn" target="_blank"  class="link">江苏省重点实验室</a></li>
          <li><a href="http://pbd.gzu.edu.cn/" target="_blank" class="link">省部公共重点实验室</a></li>
          <li><a href="http://www.lynu.edu.cn" target="_blank" class="link">洛阳师范学院</a></li>
          <li><a href="http://obdm.zjou.edu.cn/" target="_blank" class="link">浙江海洋大数据重点实验室</a></li>
        </ul>
      </div>
      <div class="beian-con">
        Copyright © 2021 河南省电子商务大数据处理与分析重点实验室   
    </div>
  </footer>
</template>

<script>
// import HTTP from "./../server/http";
export default {
  name: "comFoot",
  data () {
    return {
      typeList: [
        { title: "" },
      ]
    }
  },
  created () {
    // let url = "/api/source/getSourceType";
    // let data = {};
    // HTTP.get(url,data,res=>{
    //   if(Number(res.code) === 0){
    //     this.typeList = res.data.slice(0,2);
    //   }
    // },false)
  },
  methods: {
    jumpDetail (typeId) {
      this.$router.push({
        name: "resourceDetail",
        params: {
          typeId: typeId
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
footer {
  -o-user-select: none;
  /// 火狐 firefox /
  -moz-user-select: none;
  ///webkit浏览器/
  -webkit-user-select: none;
  /// IE10 + /
  -ms-user-select: none;
  ///早期的浏览器/
  -khtml-user-select: none;
  user-select: none;
  margin-top: 40px;
  // background-color: #2d2d2d;
  background-color: #e2e7e8;
  padding: 1.09rem .34rem 0;
  margin-bottom: -2px;

  .foot-con {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }

  .ft-h4 {
    font-size: .2rem;
    // color: #ffffff;
    color:#141313;
    margin-bottom: .4rem;
    position: relative;
  }
  .ft-h4:after{
    position: absolute;
    content: "";
    width: .4rem;
    height: 2px;
    left: 0;
    background: linear-gradient(90deg, #3BBFFD, #0E5EAB);
    bottom: -0.2rem;
  }

  .list-con {
    // display: flex;
    width: 100%;
    // flex-direction: column;

    .ft-text {
      color:#141313;
      // color: rgba(255, 255, 255, 0.77);
      width: 100%;
      display: block;
      font-size: .14rem;
      margin-bottom: .2rem;
    }

    .ft-text:hover {
      color: #161616;
      cursor: pointer;
    }

    .el-button {
      width: 20px;
      height: 20px;
      background: none;
        // background-color: #2d2d2d;
        border: none;
        text-align: left;
        padding: 0;
        .xcx-icon {
          width: 20px;
          height: 20px;
        }
      }
    
  }
}

.beian-con {
  color: #141313;
  text-align: left;
  border-top: 1px solid rgba(22, 20, 20,.16);
  // border-top: 1px solid rgba(255,255,255,.16);
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: .6rem;
  line-height: .6rem;
}
.ship{
  color: #141313;
  text-align: left;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: .6rem;
  line-height: .6rem;
  ul{
    height: 100%;
    li{
      height: 100%;
      display: inline-block;
      .friendship{
        color:#1C8EF9;
        display: block;
        height: 34px;
        margin: 0 20px 0 0;
      }
      .link{
        display: block;
        height: 34px;
        margin: 0 20px 0 0;
        color: #6F7071;
      }
      .link:hover{
        color:#1C8EF9;
      }
    }
  }
}

// 屏幕自适应  当浏览器可视区域宽度小于1200xp时
@media screen and (max-width: 1200px) {
  footer {
    font-size: 12px;
    padding: 20px 20px 10px;
    line-height: 20px;
  }
  .beian-con {
    margin-bottom: 0;
    float: left;
  }
}
.el-popover.xcx-pop {
  background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.77);
  box-shadow: none;
  .QR-img {
    width: 100px;
    height: 100px;
    margin: 12px;
  }
}

.login-btn{
  padding: 4px 6px;
  background-color: #0379CB;
  color: #fff;
  border-radius: 4px;
  font-size: .14rem;
  margin-right: 10px;
}
.wx{
    float: left;
    height:38px;
    width: 38px;
    // margin-right: 30px;
    background-image: url(../assets/img/wx.png);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
}
// .wx:hover{
//   background: url(../assets/img/weixin.png) no-repeat left bottom;
// }
.arrow{
  width: 0;
    height: 0;
    position: absolute;
    left: 10px;
    bottom: -10px;
    border-top: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.wx:hover .ziti{
  display: block;
}
.ziti{
    width: 120px;
    height: 150px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    position: relative;
    bottom: 160px;
    display: none;
    p{
      font-family: "微软雅黑";
      font-size: 14px;
      color: #000;
      float: left;
      height: 20px;
      line-height: 25px;
      width: 100%;
      text-align: center;
    }
}

.wb{
  float: left;
    height: 38px;
    width: 38px;
    background-image: url(../assets/img/wb.png);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
}

.wb:hover .wbt{
  display: block;
}
.wbt{
    width: 120px;
    height: 150px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    position: relative;
    bottom: 160px;
    display: none;
    p{
      font-family: "微软雅黑";
      font-size: 14px;
      color: #000;
      float: left;
      height: 20px;
      line-height: 25px;
      width: 100%;
      text-align: center;
      // background-color: #0a7241;
    }
}

</style>

